import React, { useEffect, useState } from 'react';
import {
  Box, Flex, VStack, Text, Image, Divider, Link
} from '@chakra-ui/react';
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  const [data, setData] = useState({ portfolio: [], actors: [], recruitment: [] });

  useEffect(() => {
    fetch('/data.json')
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  useEffect(() => {
    const element = document.querySelector('.text-Entertainment');
    const addNeonEffect = () => {
      element.classList.add('neon-effect');
    };

    element.addEventListener('animationend', addNeonEffect);

    return () => {
      element.removeEventListener('animationend', addNeonEffect);
    };
  }, []);

  localStorage.setItem('chakra-ui-color-mode', "Dark");
  const handleOpenTab = () => {
    window.open(
      'https://www.trustmarkthai.com/callbackData/popup.php?data=9cae74c7-26-6-a5f8e9d612422f7b5e116130164f6bb80d1155&markID=firstmar',
      '_blank'
    );
  };


  return (
    <Flex minH="100vh" flexDirection="column">
      {/* Header Section */}
      <Flex flex="1" direction={{ base: 'column', md: 'row' }}>
        {/* Title on the left side */}
        <Box flex="1" bg="#000000" color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={4}>
          <Text className='text-Hnawny' fontSize="5xl" textAlign="left" fontWeight="bold" width={{ base: "100%", md: "50%" }}>Hnawny</Text>
          <Text className='text-Entertainment' fontSize="5xl" textAlign="left" fontWeight="bold" width={{ base: "100%", md: "50%" }}>Cloud Group</Text>
          {/* Navbar Section */}

          <Text className='text-Entertainment'>ให้บริการทุกท่านด้วยความจริงใจ และแก้ไขปัญหาอย่างตรงจุด 🌻</Text>
        </Box>

        {/* Content Section */}
        <Box flex="1" bg="#000000" p={4} overflowY={{ md: 'auto' }} zIndex="999" maxH={{ base: '50vh', md: '100vh' }}>
          <VStack align="start" spacing={8} divider={<Divider borderColor="gray.600" />}>
            {/* About Us Section */}
            {/* <Box id="about" className='text-animate-About'>
              <Text fontSize="3xl" fontWeight="bold" color="white">เกี่ยวกับพวกเรา</Text>
              <Text color="white">พวกเราหลงใหลในการสร้างผลงานหลากหลายแนว ไม่ว่าจะเป็นหนังสั้นหรือสื่อบันเทิงต่าง ๆ ที่อยากให้ผู้ชมได้สนุกและซึมซับเรื่องราวไปกับเรา ทีมของเรารวมตัวกันจากคนที่ชื่นชอบและรักในการทำงานศิลปะหลากหลายด้าน ทั้งเขียนบท กำกับ การแสดง ถ่ายทำ ตัดต่อ รวมถึงการสร้างกราฟิก เราใส่ใจในทุกขั้นตอน เพราะอยากให้ผลงานของเราสะท้อนถึงความรู้สึกและประสบการณ์ที่หลากหลาย เราตั้งใจจะเล่าเรื่องราวที่มีความหมายและเชื่อมโยงกับชีวิตจริง ตั้งแต่เรื่องราวที่ทำให้ยิ้มและหัวเราะ ไปจนถึงเรื่องที่ชวนคิดและสะท้อนอารมณ์ เป้าหมายของเราคืออยากให้ผลงานของเราไม่ได้เป็นแค่ความบันเทิง แต่ยังเป็นแรงบันดาลใจให้กับทุกคนที่ได้ชม</Text>
            </Box> */}

            {/* Actors Section */}
            <Box id="actors" className='text-animate-Actors'>
              <Text fontSize="3xl" color="white" fontWeight="bold">ทีมงาน</Text>
              <Flex wrap="wrap" gap={4} py={4} justify="center">
                {data.actors.map((actor) => (
                  <Box 
                    key={actor.id} 
                    // minW="220px"  
                    width={{ base: "45%", md: "30%" }} // แบ่งเป็น 2 คอลัมน์ (50%) บนหน้าจอเล็ก, 2 คอลัมน์ (45%) บนหน้าจอกลาง, 3 คอลัมน์ (30%) บนเดสก์ท็อป
                    bg="gray.700" 
                    borderRadius="md" 
                    position="relative" 
                    boxShadow="lg" 
                    mb={4}
                  >
                    <Image src={actor.img} alt={actor.name} borderRadius="md" />
                    <Box position="absolute" bottom={3} left={3} w="full" bg="rgba(0, 0, 0, 0.6)" borderRadius="md" p={3}>
                      <Text fontWeight="bold" color="purple.300">{actor.name}</Text>
                      <Text fontWeight="bold" color="purple.300">{actor.details}</Text>
                      <Link href={actor.link} isExternal color="purple.300" mt={2} display="block">
                        <i class="bi bi-browser-safari"></i> WebSite
                      </Link>
                    </Box>
                  </Box>
                ))}
              </Flex>
            </Box>

            {/* Contact Section */}
            <Box id="contact" className='text-animate-Actors'>
              <Text fontSize="3xl" fontWeight="bold" color="white">ติดต่อเรา</Text>
              <Text color="white">ไม่ว่าจะสอบถามข้อมูล ขอความร่วมมือ หรือเพียงแค่ทักทาย สามารถติดต่อเราได้ที่:</Text>
              
              {/* Email */}
              <Text fontWeight="bold" color="purple.300">Email: contact@hnawny.in.th</Text>
              
              {/* Phone */}
              <Text fontWeight="bold" color="purple.300">Phone: 052-072-602 (09:00 - 16:30 น)</Text>
             
            </Box>


            {/* Footer */}
            <Box as="footer" color="white" w={"full"} textAlign="center" p={4}>
              <img onClick={handleOpenTab} src="https://www.trustmarkthai.com/trust_banners/bns_registered.png" alt="" />
              <Text>&copy; 2022 - 2024 <Text as={'b'} color="purple.600">Powered by</Text> Hnawny Cloud Group | All Rights Reserved </Text>
            </Box>
          </VStack>
        </Box>
      </Flex>

      
    </Flex>
  );
};

export default App;
